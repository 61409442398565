<template>
  <div class="resume">
    <panel-title icon="el-icon-document" title="我的简历" />

    <panel-content>
      <template #filter>
        <div>
          <span>简历名称：</span>
          <el-input
            size="medium"
            style="width: 250px; margin: 0 10px"
            placeholder="请输入简历名称..."
          />
          <el-button type="primary" size="medium">查询</el-button>
          <el-button type="primary" size="medium">创建更多简历</el-button>
        </div>
      </template>
      <template #content>
        <el-empty
          style="margin-top: 30px"
          description="根据您的条件无法找到相应的简历信息，请您换个搜索条件试试吧"
        ></el-empty>
      </template>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
export default {
  components: { PanelTitle, PanelContent },
};
</script>

<style>
</style>